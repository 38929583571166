<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        width="400"
        scrollable
    >

      <v-card>

        <v-card-title>
          Filtrer par
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">

          <v-radio-group v-model="type" hide-details label="Type des SMS">
            <v-radio
                v-for="(type,i) in types"
                :key="i"
                :label="type.name"
                :value="type.value"
            ></v-radio>
          </v-radio-group>

          <v-radio-group v-model="youtube_link" hide-details label="Video youtube">
            <v-radio label="Oui" value="yes"></v-radio>
            <v-radio label="Non" value="no"></v-radio>
          </v-radio-group>

          <v-autocomplete :loading="isDBLoading"
                          :disabled="isDBLoading"
                          v-model="databaseIds"
                          :items="databases"
                          multiple
                          chips
                          small-chips
                          deletable-chips
                          item-value="id"
                          item-text="name"
                          label="Bases de données"
          />

          <v-dialog
              ref="dialog"
              v-model="modal"
              persistent
              width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  @click:clear="dates = []"
                  v-model="dateRangeText"
                  readonly
                  append-icon="mdi-calendar-outline"
                  clearable
                  label="Planification"
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker v-model="dates" scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Fermer
              </v-btn>
              <v-btn text
                     color="primary"
                     :disabled="dates.length < 2"
                     @click="modal = false">
                ok
              </v-btn>
            </v-date-picker>
          </v-dialog>

        </v-card-text>


        <v-divider/>

        <v-card-actions>
          <v-btn color="primary"
                 @click="handleFilter"
                 :disabled="(dates.length < 2 ) && (databaseIds.length === 0) && (!type) && (!youtube_link)"
                 depressed block
                 type="submit">
            <v-icon left>mdi-magnify</v-icon>
            Rechercher
          </v-btn>
        </v-card-actions>

      </v-card>


    </v-dialog>

  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      isLoading: false,
      isDBLoading: false,
      modal: false,
      dialog: false,
      type: null,
      youtube_link: null,
      dates: [],
      databaseIds: [],
      databases: [],
    }
  },
  methods: {
    open() {
      this.dates = []
      this.databaseIds = []
      this.type = null
      this.youtube_link = null
      this.dialog = true
      this.getDatabases()
    },
    close() {
      this.dialog = false
    },
    handleFilter() {
      let data = {
        dates: this.dates,
        databaseIds: this.databaseIds,
        type: this.type,
        youtube_link: this.youtube_link,
      }
      this.$emit('filter', data)
      this.close()
    },
    getDatabases() {
      this.isDBLoading = true
      HTTP.get('/v1/users/databases').then(res => {
        this.isDBLoading = false
        this.databases = res.data.data
      }).catch(err => {
        this.isDBLoading = false
        console.log(err)
      })
    },
  },
  computed: {
    dateRangeText: {
      get() {
        return this.dates.join(' ~ ')
      },
      set(dates) {
        return dates
      }
    },
    types() {
      return [
        {name: 'Tous le monde', value: 'all'},
        {name: 'Persons qui non pas de compte GIFTY', value: 'gifty'},
      ]
    }
  },
}
</script>

