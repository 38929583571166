<template>
  <div>
    <v-container fluid>

      <div class="main">

        <v-row v-if="isLoading">
          <v-col v-for="item in 3" :key="item" cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text>
                <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text>
                <v-row align="center" justify="center">
                  <v-col cols="3">
                    <v-avatar color="primary" size="55">
                      <v-icon color="white">mdi-message-text-outline</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ total }}
                    </div>
                    Total campagnes
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text>
                <v-row align="center" justify="center">
                  <v-col cols="3">
                    <v-avatar color="success" size="55">
                      <v-icon color="white">mdi-currency-usd</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ CurrencyFormatting(sms_cost_total) }} DZD
                    </div>
                    Coût total des SMS
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" lg="4">
            <v-card class="rounded-lg shadow">
              <v-card-text>
                <v-row align="center" justify="center">
                  <v-col cols="3">
                    <v-avatar color="gifty" size="55">
                      <v-icon color="white">mdi-currency-usd</v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      {{ CurrencyFormatting(amount_total) }} DZD
                    </div>
                    Coût total envoyé
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

        </v-row>

        <div class="d-lg-flex d-block justify-space-between my-3">
          <v-spacer/>
          <v-btn class="rounded-lg"
                 color="gifty"
                 dark
                 depressed
                 to="/campaigns/create">
            <v-icon left>mdi-plus</v-icon>
            Ajouter
          </v-btn>
        </div>

        <v-card class="rounded-lg shadow">

          <v-overlay :value="overlay"
                     absolute
                     color="white"
                     opacity="0.5">
            <v-progress-circular
                    color="primary"
                    indeterminate
                    size="50"
            ></v-progress-circular>
          </v-overlay>

          <v-card-title>
            <div>
              <v-text-field
                      v-model="keyword"
                      append-icon="mdi-magnify"
                      clearable
                      dense
                      filled
                      hide-details
                      placeholder="Recherche..."
                      rounded
                      single-line
              ></v-text-field>
            </div>

            <v-spacer/>

            <v-btn class="text-none" color="grey"
                   text>
              <v-icon color="blue" left small>mdi-square-rounded</v-icon>
              Persons qui non pas de compte GIFTY
            </v-btn>

            <v-btn class="text-none" color="grey"
                   text>
              <v-icon color="success" left small>mdi-square-rounded</v-icon>
              Tous le monde
            </v-btn>

            <v-btn color="grey"
                   text
                   @click="$refs.filterDialog.open()">
              <v-icon left>mdi-filter-outline</v-icon>
              Filtre
            </v-btn>

            <v-btn color="grey"
                   icon
                   @click="handleFilter">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>

          </v-card-title>

          <v-divider/>

          <v-card-text v-if="isLoading" class="pa-0">
            <v-skeleton-loader type="table"/>
          </v-card-text>

          <div v-else>

            <v-card-text class="pa-0">

              <v-simple-table v-if="campaigns.length">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th>Titre</th>
                    <th>M.envoyé</th>
                    <th>C.total</th>
                    <th>C.SMS</th>
                    <th class="text-center">T.POS</th>
                    <th>Planification</th>
                    <th>Date de création</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item,i) in campaigns" :key="i">

                    <td class="text-no-wrap">
                      <v-icon :color="item.type === 'gifty' ? 'blue' : 'success'" small>
                        mdi-square-rounded
                      </v-icon>
                      {{ item.title }}
                    </td>

                    <td class="text-no-wrap">
                                           <span class="gifty--text font-weight-medium">
                                             {{ CurrencyFormatting(item.amount) }} DZD
                                           </span>
                    </td>

                    <td class="text-no-wrap">
                                           <span class="primary--text font-weight-medium">
                                             {{ CurrencyFormatting(item.messages_sum_amount) }} DZD
                                           </span>
                    </td>

                    <td class="text-no-wrap">
                                            <span class="font-weight-medium">
                                              {{ CurrencyFormatting(item.sms_cost) }} DZD
                                            </span>
                    </td>


                    <td class="text-center">
                      <v-chip color="primary" small>
                        <v-icon left small>mdi-account-check</v-icon>
                        {{ item.counter }}
                      </v-chip>
                    </td>

                    <td class="text-no-wrap">
                      <v-icon :style="{marginTop:'-3px'}"
                              color="gifty"
                              small>mdi-clock-outline
                      </v-icon>
                      {{ item.scheduled_datetime }}
                    </td>

                    <td class="text-no-wrap">
                      <v-icon :style="{marginTop:'-3px'}"
                              color="gifty"
                              small>mdi-clock-outline
                      </v-icon>
                      {{ item.created_at }}
                    </td>

                    <td>
                      <div class="d-flex">

                        <v-btn color="gifty"
                               icon
                               @click="$refs.showDialog.open(item)">
                          <v-icon>mdi-plus-circle</v-icon>
                        </v-btn>

                        <v-btn :to="'/campaigns/'+item.id+'/duplicate'" icon>
                          <v-icon>mdi-content-duplicate</v-icon>
                        </v-btn>
                        <v-btn icon @click="remove(item.id)">
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>

                      </div>
                    </td>

                  </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <div v-else>
                <NoResults @reload="getCampaigns"/>
              </div>

            </v-card-text>

            <v-divider v-if="total > 10"/>

            <v-card-actions v-if="total > 10">

              <div :style="{width : '120px'}">
                <v-select v-model="perPage"
                          :items="[10,20,30,50,100]"
                          dense
                          hide-details
                          label="Ligne par page"
                          outlined
                ></v-select>
              </div>

              <v-spacer/>

              <v-pagination v-if="total > 10"
                            v-model="pagination.current"
                            :length="pagination.total"
                            circle
                            total-visible="8"
                            @input="getCampaigns"></v-pagination>

            </v-card-actions>

          </div>

        </v-card>

      </div>

      <ShowDialog ref="showDialog"/>
      <FilterDialog ref="filterDialog" @filter="handleFilter"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import ShowDialog from "./components/ShowDialog";
import FilterDialog from "./components/FilterDialog";

export default {
    components: {
        ShowDialog,
        FilterDialog,
        NoResults,
    },
    data() {
        return {
            id: null,
            isLoading: false,
            overlay: false,

            keyword: null,
            type: null,
            youtube_link: null,
            dates: [],
            databaseIds: [],

            campaigns: [],
            total: 0,
            perPage: 10,
            pagination: {
                current: 1,
                total: 0
            },
            amount_total: 0,
            sms_cost_total: 0,

        };
    },
    methods: {
        getCampaigns() {
            this.isLoading = true
            HTTP.get('/v1/campaigns?page=' + this.pagination.current, {
                params: {
                    perPage: this.perPage,
                    keyword: this.keyword,
                    dates: this.dates,
                    databaseIds: this.databaseIds,
                    type: this.type,
                    youtube_link: this.youtube_link,
                }
            }).then(res => {
                this.isLoading = false
                this.pagination.current = res.data.data.current_page;
                this.pagination.total = res.data.data.last_page;
                this.total = res.data.data.total;

                this.campaigns = res.data.data.data
                this.amount_total = res.data.amount_total
                this.sms_cost_total = res.data.sms_cost_total

            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        handleFilter(e) {
            this.dates = e.dates
            this.databaseIds = e.databaseIds
            this.type = e.type
            this.youtube_link = e.youtube_link
            this.getCampaigns()
        },
        remove(id) {
            this.id = id
            this.$confirm_dialog = true
        },
        deleteItem() {
            this.overlay = true
            let data = {
                message_campaign_id: this.id
            }
            HTTP.post('/v1/campaigns/delete', data).then(() => {
                this.overlay = false
                let i = this.campaigns.findIndex(el => el.id === this.id)
                this.$delete(this.campaigns, i)
                this.id = null
            }).catch(err => {
                this.overlay = false
                console.log(err)
            })
        }
    },
    created() {
        this.getCampaigns()
    },
    watch: {
        perPage() {
            this.getCampaigns()
        },
        keyword() {
            this.getCampaigns()
        },
        $is_confirm: function (el) {
            if (el) {
                this.deleteItem()
                this.$is_confirm = false
            }
        },
    },
};
</script>

<style scoped></style>
