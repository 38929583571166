<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        width="780"
        scrollable
    >
      <v-card height="90vh">

        <v-card-title>
          Vidéo youtube
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-4">

          <div class="video-container">
            <iframe
                :src="'//www.youtube.com/embed/'+ link+'?autoplay=1&mute=1&loop=1'"
                frameborder="0"
                allowfullscreen
                class="video"
                allow="autoplay; encrypted-media">
            </iframe>
          </div>

        </v-card-text>

      </v-card>

    </v-dialog>

  </div>
</template>

<script>

export default {
  data() {
    return {
      link: null,
      dialog: false,
    }
  },
  methods: {
    open(link) {
      this.link = link
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
  },
}
</script>

<style scoped>
.video-container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 75vh;
}
</style>
